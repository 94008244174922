import React, { useState } from "react";

import {
  Carousel,
  CarouselContent,
  CarouselItem,
  type CarouselApi,
} from "@/components/ui/carousel";

const slides = [
  {
    title: "Earn passive Income with Staking",
    description:
      "Stake your cryptocurrencies in numerous SST pools and earn attractive rewards.",
    coins: [
      {
        name: "TRON/SST",
        symbol: "TRX/SST",
        imgSrc:
          "https://cdn.prod.website-files.com/635397204d0d410851921320/665ee65bf838352751a92faf_tron.webp",
        overlapImgSrc:
          "https://cdn.prod.website-files.com/635397204d0d410851921320/665ee65b6bf82471f950a2b5_logo-icon.webp",
      },
      {
        name: "USDT/SST",
        symbol: "USDT/SST",
        imgSrc:
          "https://cdn.prod.website-files.com/635397204d0d410851921320/665ee65bb24667e0f7451087_tether.webp",
        overlapImgSrc:
          "https://cdn.prod.website-files.com/635397204d0d410851921320/665ee65b6bf82471f950a2b5_logo-icon.webp",
      },
      {
        name: "SOLO SST",
        symbol: "SST",
        imgSrc:
          "https://cdn.prod.website-files.com/635397204d0d410851921320/665ee65b6bf82471f950a2b5_logo-icon.webp",
        overlapImgSrc: "",
      },
    ],
    buttonText: "Stake Now",
    buttonImgSrc:
      "https://cdn.prod.website-files.com/635397204d0d410851921320/665ee65bb8610e14f409449c_stack.webp",
  },
  {
    title: "Coins you can trade with us",
    description:
      "Take advantage of the opportunity to swap and sell your rewards from the staking pools. Exchange these cryptocurrencies in all directions.",
    coins: [
      {
        name: "TRON",
        symbol: "TRX",
        price: "$0,1031",
        imgSrc:
          "https://cdn.prod.website-files.com/635397204d0d410851921320/665ee65bf838352751a92faf_tron.webp",
      },
      {
        name: "USDT",
        symbol: "USDT",
        price: "$0,1031",
        imgSrc:
          "https://cdn.prod.website-files.com/635397204d0d410851921320/665ee65bb24667e0f7451087_tether.webp",
      },
      {
        name: "SST",
        symbol: "SST",
        price: "$0,1031",
        imgSrc:
          "https://cdn.prod.website-files.com/635397204d0d410851921320/665ee65b6bf82471f950a2b5_logo-icon.webp",
      },
    ],
    buttonText: "Trade Now",
    buttonImgSrc:
      "https://cdn.prod.website-files.com/635397204d0d410851921320/665ee65b0c0dbdc98f392d2c_trade.webp",
  },
];

const PromoStaking = () => {
  const [api, setApi] = React.useState<CarouselApi>();
  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <section className="slider relative">
      <Carousel
        opts={{
          align: "start",
          loop: true,
        }}
        setApi={setApi}
      >
        <CarouselContent className="slider-show w-slider">
          {slides.map((slide, index) => (
            <CarouselItem
              key={index}
              className="slide-base w-slide opacity-100 z-50"
            >
              <h2 className="headline-h2">{slide.title}</h2>
              <p className="regular-text">{slide.description}</p>
              <div className="grid lg:grid-cols-2 gap-3">
                <div className="flex flex-col lg:flex-row gap-3">
                  {slide.coins.map((coin, idx) => (
                    <div key={idx} className="coin pool">
                      <div className="coin-left">
                        <img
                          src={coin.imgSrc}
                          loading="lazy"
                          alt={`icon ${coin.name}`}
                          className="coin-icon"
                        />
                        {coin.overlapImgSrc && (
                          <img
                            src={coin.overlapImgSrc}
                            loading="lazy"
                            alt={`icon ${coin.name}`}
                            className="coin-icon overlap"
                          />
                        )}
                      </div>
                      <div className="w-layout-vflex">
                        <h3 className="headline-h5 pool-name">{coin.name}</h3>
                        <p className="coin-index">{coin.symbol}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <a
                  href="#"
                  className="btn btn-primary stretched h-full py-[21px] lg:justify-self-end"
                >
                  <img
                    src={slide.buttonImgSrc}
                    loading="lazy"
                    alt=""
                    className="icon-xs"
                  />
                  <div className="text-block">{slide.buttonText}</div>
                </a>
              </div>
            </CarouselItem>
          ))}
        </CarouselContent>
        <div className="slide-nav w-slider-nav">
          {slides.map((_, index) => (
            <div
              key={index}
              className={`w-slider-dot ${index === currentIndex ? "w-active" : ""}`}
              onClick={() => {
                api?.scrollTo(index);
                setCurrentIndex(index);
              }}
              aria-label={`Show slide ${index + 1} of ${slides.length}`}
              role="button"
              tabIndex={0}
            ></div>
          ))}
        </div>
      </Carousel>
      <img
        src="https://cdn.prod.website-files.com/635397204d0d410851921320/665f0c843daa1fdfedf7666b_background-dots.svg"
        loading="lazy"
        alt=""
        className="background-dots"
        style={{ zIndex: -1 }}
      />
    </section>
  );
};

export default PromoStaking;

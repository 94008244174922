import type { MetaFunction } from "@remix-run/node";
import { motion } from "framer-motion";

import PromoStaking from "~/components/ui/promo_staking";

export const meta: MetaFunction = () => {
  return [
    { title: "SocialSwap.io - Social Staking & Social Energy" },
    {
      name: "description",
      content: "SocialSwap.io - Social Staking & Social Energy",
    },
  ];
};

const Index = () => {
  return (
    <>
      <section className="hero">
        <h1 className="heading-hero">Decentralized Exchange</h1>
        <motion.img
          src="https://cdn.prod.website-files.com/635397204d0d410851921320/665ee68356a6c8970b62e06a_token-hero.webp"
          loading="lazy"
          width="610"
          alt=""
          srcSet="https://cdn.prod.website-files.com/635397204d0d410851921320/665ee68356a6c8970b62e06a_token-hero-p-500.webp 500w, https://cdn.prod.website-files.com/635397204d0d410851921320/665ee68356a6c8970b62e06a_token-hero.webp 609w"
          sizes="(max-width: 479px) 100vw, (max-width: 767px) 265.359375px, (max-width: 991px) 331.6875px, 610px"
          className="token-swoosh-1"
          initial={{ x: -500 }}
          animate={{ x: 0 }}
          transition={{ duration: 1 }}
        />
        <div className="hero-texts">
          <p className="hero-paragraph">
            The easiest way to exchange and stake cryptocurrencies. Generate
            passive income with Energy
          </p>
          <h2 className="hero-headline-big">on the Tron Blockchain.</h2>
          <a href="/trade" className="hero-image-launch w-inline-block">
            <img
              src="https://cdn.prod.website-files.com/635397204d0d410851921320/66686ab25d6ca74ef6f664ab_launch-btn.webp"
              loading="lazy"
              alt="Button: Unlock Energy on Tron Blockchain"
              className="hero-image-launch"
            />
          </a>
        </div>
      </section>
      <section className="energy">
        <div className="w-layout-hflex flex-block">
          <h2 className="headline-h2 _w-40">
            Discover the <br />
            Power of TRON Energy{" "}
          </h2>
          <div className="energy-text">
            <p className="regular-text">
              Take advantage of the opportunity to execute completely free
              transactions and earn passive income with cryptocurrencies.
              Provide energy for Tron Blockchain users or use the energy for
              yourself.
            </p>
            <a href="index.html#" className="btn btn-primary w-inline-block">
              <img
                src="https://cdn.prod.website-files.com/635397204d0d410851921320/665ee65bd6e16530d6cada0d_flash.webp"
                loading="lazy"
                alt=""
                className="icon-xs"
              />
              <div className="text-block">Coming Soon</div>
            </a>
          </div>
        </div>
      </section>
      <PromoStaking />
      <section
        data-w-id="5825e3b8-7174-8325-e19b-83c06c719664"
        className="features"
      >
        <h2 className="headline-h2">Benefit from our Features</h2>
        <div className="feature-list w-row">
          <div className="feature w-col w-col-4 w-col-small-small-stack w-col-tiny-tiny-stack">
            <div className="feature-top">
              <img
                src="https://cdn.prod.website-files.com/635397204d0d410851921320/665ee627fce7fdfd0e59d53e_money-stacked.webp"
                loading="lazy"
                alt=""
                className="feature-icon"
              />
            </div>
            <div className="feature-text">
              <h3 className="heading-h3">Lowest Transaction Cost</h3>
              <p className="feature-text">
                Benefit from the <br />
                Lowest Transaction Costs{" "}
              </p>
            </div>
          </div>
          <div className="feature middle w-col w-col-4 w-col-small-small-stack w-col-tiny-tiny-stack">
            <div className="feature-top _2">
              <img
                src="https://cdn.prod.website-files.com/635397204d0d410851921320/665ee67eb53002ccee686207_blockchain.webp"
                loading="lazy"
                alt=""
                className="feature-icon"
              />
            </div>
            <div className="feature-text">
              <h3 className="heading-h3">Fast Blockchain Protocol</h3>
              <p className="feature-text">
                Unleashing the Power of Instantaneous Blockchain Operations
              </p>
            </div>
          </div>
          <div className="feature w-col w-col-4 w-col-small-small-stack w-col-tiny-tiny-stack">
            <div className="feature-top _3">
              <img
                src="https://cdn.prod.website-files.com/635397204d0d410851921320/665ee627fce7fdfd0e59d535_shield.webp"
                loading="lazy"
                alt=""
                className="feature-icon"
              />
            </div>
            <div className="feature-text">
              <h3 className="heading-h3">Smart Contracts</h3>
              <p className="feature-text">
                Secure, Self-Executing Contracts for Effortless Transactions
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="community">
        <div className="columns w-row">
          <div className="column-community w-col w-col-6 w-col-stack">
            <div>
              <h2 className="headline-h2">What makes SocialSwap Special?</h2>
              <p className="regular-text">It&#x27;s a Community Project.</p>
            </div>
            <div className="w-layout-hflex d-flex">
              <p className="bold-text">Join us!</p>
              <div className="w-layout-hflex social-media-links">
                <a
                  href="index.html#"
                  className="social-media-link w-inline-block"
                >
                  <img
                    src="https://cdn.prod.website-files.com/635397204d0d410851921320/665ee65be23d7bdf528a08ee_instagram.webp"
                    loading="lazy"
                    alt="instagram"
                  />
                </a>
                <a
                  href="index.html#"
                  className="social-media-link w-inline-block"
                >
                  <img
                    src="https://cdn.prod.website-files.com/635397204d0d410851921320/665ee65b39ffa5648812b6cc_telegram.webp"
                    loading="lazy"
                    alt="telegram"
                  />
                </a>
                <a
                  href="index.html#"
                  className="social-media-link w-inline-block"
                >
                  <img
                    src="https://cdn.prod.website-files.com/635397204d0d410851921320/665ee65ba27fc3ea728921ff_twitter.webp"
                    loading="lazy"
                    alt="Twitter"
                  />
                </a>
                <a
                  href="index.html#"
                  className="social-media-link w-inline-block"
                >
                  <img
                    src="https://cdn.prod.website-files.com/635397204d0d410851921320/665ee65bacd0a633b6223db2_youtube.webp"
                    loading="lazy"
                    alt="Youtube"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="dv-flex w-col w-col-6 w-col-stack">
            <div className="w-layout-hflex _w-100">
              <img
                src="https://cdn.prod.website-files.com/635397204d0d410851921320/665ee627fce7fdfd0e59d53a_institute.webp"
                loading="lazy"
                alt=""
                className="icon-l"
              />
              <div className="w-layout-vflex community-text">
                <h3 className="heading-h3">Governance</h3>
                <p className="paragraph-2">
                  SocialSwap is designed to be governed by you, reflecting our
                  commitment to democracy.
                </p>
              </div>
            </div>
            <div className="w-layout-hflex _w-100">
              <img
                src="https://cdn.prod.website-files.com/635397204d0d410851921320/665ee62db0e17d60fb534805_community.webp"
                loading="lazy"
                alt=""
                className="icon-l"
              />
              <div className="w-layout-vflex community-text">
                <h3 className="heading-h3">Exclusive Community</h3>
                <p className="paragraph-2">
                  In the past, many community events took place and the
                  co-creators exchanged ideas with the community.
                </p>
              </div>
            </div>
            <div className="w-layout-hflex _w-100">
              <img
                src="https://cdn.prod.website-files.com/635397204d0d410851921320/665ee6279f1ae58b3b07ce47_deflation.webp"
                loading="lazy"
                alt=""
                className="icon-l"
              />
              <div className="w-layout-vflex community-text">
                <h3 className="heading-h3">Deflation</h3>
                <p className="paragraph-2">
                  The SocialSwap Token is designed to decrease in supply
                  ensuring that it becomes scarcer over time.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="community">
        <div className="columns mobile w-row">
          <div id="audits" className="column-audits w-col w-col-6">
            <div>
              <h2 className="headline-h2">Audits &amp;Security</h2>
              <p className="regular-text">
                SocialSwap.io has been reviewed and audited by several
                independent security companies.
              </p>
            </div>
            <div className="w-layout-hflex d-flex mobile">
              <img
                src="https://cdn.prod.website-files.com/635397204d0d410851921320/665ee6272f33c30686a85e6d_certik.webp"
                loading="lazy"
                alt="logo: certik"
                className="audit-companies"
              />
              <img
                src="https://cdn.prod.website-files.com/635397204d0d410851921320/665ee6829dcc726cc0147a6b_tp.webp"
                loading="lazy"
                alt="logo: tp audit"
                className="audit-companies"
              />
            </div>
          </div>
          <div className="column-audits w-col w-col-6">
            <div>
              <h2 className="headline-h2">Our Partners</h2>
              <p className="regular-text">
                SocialSwap.io has known industry partners for secure and safe
                exchange
              </p>
            </div>
            <div className="w-layout-hflex d-flex mobile">
              <img
                src="https://cdn.prod.website-files.com/635397204d0d410851921320/665ee682542bc9c8e2ce466f_token-goodies.webp"
                loading="lazy"
                alt="logo: token goodies"
                className="partner-companies"
              />
              <img
                src="https://cdn.prod.website-files.com/635397204d0d410851921320/665ee68ebfd93e4099bfdcfa_mountain-wolf.webp"
                loading="lazy"
                alt="logo: mountain wolf"
                className="partner-companies"
              />
            </div>
          </div>
        </div>
      </section>
      <div className="footer">
        <div className="footer-wrap">
          <ul className="left-list">
            <li>
              <a href="index.html#" className="footer-link w-inline-block">
                <img
                  loading="lazy"
                  src="https://cdn.prod.website-files.com/635397204d0d410851921320/665ee68e7367ac28ad5c7c42_logo.webp"
                  alt="logo: socialswap"
                  className="footer-img"
                />
              </a>
            </li>
            <li className="footer-link">
              <a href="index.html#" className="w-inline-block">
                <img
                  loading="lazy"
                  src="https://cdn.prod.website-files.com/635397204d0d410851921320/665ee682ec91b68066957957_trustpilot.webp"
                  alt="logo: trustpilot"
                  className="footer-img"
                />
              </a>
            </li>
            <li>
              <a href="index.html#" className="footer-link w-inline-block">
                <img
                  loading="lazy"
                  src="https://cdn.prod.website-files.com/635397204d0d410851921320/665ee68223f98881f4d62a35_nrg.webp"
                  alt="logo: NRG"
                  className="footer-img"
                />
              </a>
            </li>
          </ul>
          <div className="w-layout-hflex footer-right">
            <div className="w-layout-vflex footer-links">
              <h3 className="heading-h3 color-blue">SocialSwap</h3>
              <ul className="link-list">
                <li>
                  <a href="/trade" className="footer-link">
                    Trade
                  </a>
                </li>
                <li>
                  <a href="pools.html" className="footer-link">
                    Social Staking
                  </a>
                </li>
                <li>
                  <a href="social-energy.html" className="footer-link">
                    Social Energy
                  </a>
                </li>
              </ul>
            </div>
            <div className="w-layout-vflex footer-links">
              <h3 className="heading-h3 color-blue">Social Media</h3>
              <ul className="link-list">
                <li className="list-item">
                  <img
                    loading="lazy"
                    src="https://cdn.prod.website-files.com/635397204d0d410851921320/665ee65be23d7bdf528a08ee_instagram.webp"
                    alt="instagram"
                    className="img-xs"
                  />
                  <a href="index.html#" className="footer-link">
                    Instagram
                  </a>
                </li>
                <li className="list-item">
                  <img
                    loading="lazy"
                    src="https://cdn.prod.website-files.com/635397204d0d410851921320/665ee65b39ffa5648812b6cc_telegram.webp"
                    alt="telegram"
                    className="img-xs"
                  />
                  <a href="index.html#" className="footer-link">
                    Telegram
                  </a>
                </li>
                <li className="list-item">
                  <img
                    loading="lazy"
                    src="https://cdn.prod.website-files.com/635397204d0d410851921320/665ee65ba27fc3ea728921ff_twitter.webp"
                    alt="Twitter"
                    className="img-xs"
                  />
                  <a href="index.html#" className="footer-link">
                    X/Twitter
                  </a>
                </li>
                <li className="list-item">
                  <img
                    loading="lazy"
                    src="https://cdn.prod.website-files.com/635397204d0d410851921320/665ee65bacd0a633b6223db2_youtube.webp"
                    alt="Youtube"
                    className="img-xs"
                  />
                  <a href="index.html#" className="footer-link">
                    YouTube
                  </a>
                </li>
              </ul>
            </div>
            <div className="w-layout-vflex footer-links newsletter">
              <h3 className="heading-h3 color-blue">Newsletter</h3>
              <div className="w-form">
                <form
                  id="email-form"
                  name="email-form"
                  data-name="Email Form"
                  method="get"
                  className="form"
                  data-wf-page-id="635397204d0d4177ea921324"
                  data-wf-element-id="fc112256-3a8e-d70e-dc85-5d64e065c22d"
                >
                  <input
                    className="text-field-2 w-input"
                    maxLength={256}
                    name="mail-2"
                    data-name="Mail 2"
                    placeholder="Enter email"
                    type="email"
                    id="mail-2"
                  />
                  <input
                    type="submit"
                    data-wait="Wait..."
                    className="btn btn-red w-button"
                    value="Notify Me"
                  />
                </form>
                <div className="w-form-done">
                  <div>Thank you! Your submission has been received!</div>
                </div>
                <div className="w-form-fail">
                  <div>
                    Oops! Something went wrong while submitting the form.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
